import React from 'react';

import ConsultantLayout from '../../layout/ConsultantLayout';
import ConsultantHomeView from '../../views/consultant/home/consultantHomeView';
const ConsultantHomePage = (): React.ReactElement => {
  return (
    <>
      <ConsultantLayout>
        <ConsultantHomeView />
      </ConsultantLayout>
    </>
  );
};

export default ConsultantHomePage;
